.rails-bootstrap-forms-date-select select,
.rails-bootstrap-forms-time-select select,
.rails-bootstrap-forms-datetime-select select {
  display: inline-block;
  width: auto;
}

.rails-bootstrap-forms-error-summary {
  margin-top: 10px;
}
